import Vue from 'vue';
import App from './App.vue';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import GAuth from 'vue-google-oauth2';

Vue.config.productionTip = false;

Vue.use(ElementUI);

// Konfiguracja Google OAuth2 - podaj swój client_id
const gauthOption = {
  clientId: '1041440406803-73rv4i80ftu1l4qcsl6fp5bhr9kcijn4.apps.googleusercontent.com',
  scope: 'profile email',
  prompt: 'select_account'
};
Vue.use(GAuth, gauthOption);

new Vue({
  render: h => h(App),
}).$mount('#app');
