<template>
  <div id="app">
    <el-form ref="form" :model="form" label-width="120px">
      <!-- Przycisk logowania przez Google -->
      <el-form-item>
        <el-button type="primary" @click="loginWithGoogle">Zaloguj przez Google</el-button>
      </el-form-item>

      <!-- Wyświetlanie profilu użytkownika po zalogowaniu -->
      <el-form-item v-if="userProfile" label="Zalogowany użytkownik">
        <p>Imię: {{ userProfile.name }}</p>
        <p>Email: {{ userProfile.email }}</p>
      </el-form-item>

      <!-- Formularz zgłaszania reklamacji -->
      <el-form-item label="Rodzaj reklamacji">
        <el-radio-group v-model="form.complaintType">
          <el-radio v-model="form.complaintType" label="Opakowanie">Opakowanie</el-radio>
          <el-radio v-model="form.complaintType" label="Zawartość">Zawartość</el-radio>
        </el-radio-group>
      </el-form-item>

      <el-form-item v-if="form.complaintType === 'Opakowanie'" label="Problem z opakowaniem">
        <el-select v-model="form.packagingIssue" placeholder="Wybierz problem z opakowaniem">
          <el-option label="Pompka" value="Pompka"></el-option>
          <el-option label="Atomizer" value="Atomizer"></el-option>
          <el-option label="Produkt wylewa się" value="Produkt wylewa się"></el-option>
          <el-option label="Airless nie działa" value="Airless nie działa"></el-option>
          <el-option label="Odpada górna część airlessa" value="Odpada górna część airlessa"></el-option>
          <el-option label="Zgrzew tuby" value="Zgrzew tuby"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item v-if="form.complaintType === 'Zawartość'" label="Problem z zawartością">
        <el-select v-model="form.contentIssue" placeholder="Wybierz problem z zawartością">
          <el-option label="Rozwarstwienie" value="Rozwarstwienie"></el-option>
          <el-option label="Uczulenie" value="Uczulenie"></el-option>
          <el-option label="Szczypanie/Pieczenie" value="Szczypanie/Pieczenie"></el-option>
          <el-option label="Inna konsystencja" value="Inna konsystencja"></el-option>
          <el-option label="Zmiana zapachu" value="Zmiana zapachu"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="Numer partii (opcjonalnie)">
        <el-input v-model="form.batchNumber" placeholder="Wpisz numer partii"></el-input>
      </el-form-item>

      <el-form-item label="Data przydatności">
        <el-date-picker v-model="form.expirationDate" type="date" placeholder="Wybierz datę"></el-date-picker>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="handleSubmit">Zgłoś reklamację</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        complaintType: "",
        packagingIssue: "",
        contentIssue: "",
        batchNumber: "",
        expirationDate: null
      },
      userProfile: null, // Dane użytkownika po zalogowaniu
    };
  },
  methods: {
    // Metoda logowania przez Google
    loginWithGoogle() {
      this.$gAuth
        .signIn()
        .then(googleUser => {
          const profile = googleUser.getBasicProfile();
          this.userProfile = {
            name: profile.getName(),
            email: profile.getEmail()
          };
        })
        .catch(error => {
          console.error("Błąd logowania:", error);
        });
    },

    // Metoda obsługująca wysyłanie formularza
    async handleSubmit() {
      // Przygotowanie danych do wysłania
      const issue = this.form.complaintType === 'Opakowanie' 
        ? this.form.packagingIssue 
        : this.form.contentIssue;

      const data = {
        complaintType: this.form.complaintType,
        issue: issue,
        batchNumber: this.form.batchNumber,
        expirationDate: this.form.expirationDate,
        userEmail: this.userProfile ? this.userProfile.email : "Nie zalogowano"
      };

      try {
        // Wyślij dane do Google Sheets poprzez Apps Script
        const response = await fetch('https://script.google.com/a/macros/apiscosmetics.pl/s/AKfycbxTFj955zIITOx_fmZy8ZzBTJZ3cxOydyLTDNFSVUhxtraEsTlYCFNCNjzpps5qS4QV-w/exec', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        });
        
        const result = await response.json();
        if (result.status === 'success') {
          this.$message.success("Zgłoszenie zostało pomyślnie wysłane!");
        } else {
          this.$message.error("Wystąpił błąd podczas wysyłania zgłoszenia.");
        }
      } catch (error) {
        this.$message.error("Wystąpił błąd podczas wysyłania zgłoszenia.");
        console.error(error);
      }
    }
  },
};
</script>

<style>
#app {
  padding: 20px;
}
</style>
